import React, { useEffect, useState } from 'react';

import {
  Routes,
  Route,
} from "react-router-dom";

import Socket from "./Hooks/Socket"
import io from "socket.io-client";


import RouterPublic from './Routes/PublicRoutes'
import AdminRoutes from './Routes/AdminRoutes';
import CustomerRoutes from './Routes/CustomerRoutes';

/**
 * 
 * @export
 * @function Routes
 * @description Main router for app
 */

export default function () {

  let [socket, setSocket] = useState({});

  const getSocket = () => {
    try {
      socket?.disconnected()
    } catch (error) {
      console.log("e", error)
    }

    let socket = io.connect(process.env.REACT_APP_WEB_SERVICE, {
      extraHeaders: {
        "Authorization": sessionStorage.getItem('token')
      },

    })
    socket.io.on("open", () => {
      socket.io.engine.transport.on("pollComplete", () => {
        socket.io.opts.extraHeaders = {
          "Authorization": sessionStorage.getItem('token')
        }
      });
    })
    return socket
  }


  useEffect(() => {
    setSocket(getSocket)
  }, [])

  return (
    <Socket.Provider value={socket}>
      <Routes>
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/customer/*" element={<CustomerRoutes />} />
        <Route path="/*" element={<RouterPublic />} />
      </Routes>
    </Socket.Provider>

  )
}