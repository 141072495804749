import React, { Component, } from 'react';
import { Row, Col, Layout, Card, List, Tabs, Typography, Space, Image, message, Modal } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { CardQuotationInformation, CardQuotationProgress, CardQuotationAddress } from "../../Widgets/Cards"
import { FolioFormat, getResponseError } from '../../Utils';
import ModalAceptar from './ModalAceptar';
import ModalSetTrackingNumber from "./ModalSetTrackingNumber"
import '../../../Styles/Modules/Admin/quotations.scss'
import EnviosConfiguracion from './Envios';
import ModalChangeCosto from './ModalChangeCosto';

const { Content } = Layout;
const { Text, Paragraph } = Typography;

/**
 *
 *
 * @export
 * @class Quotation
 * @extends {Component}
 */
class Quotation extends Component {

    /**
     *Creates an instance of Quotation.
     * @param {*} props
     * @memberof Quotation
     */
    constructor(props) {
        super(props)
        this.state = {
            address: {},
            editable: false,
            quotation: {},
            venta: {},

            loading: false,
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.get()
    }

    componentWillUnmount() {
        this.props.setShowSearch(true)
    }

    get = ({ id } = this.props.params) => {
        this.setState({ loading: true })
        axios.get(`/cotizacion/${id}`, {
            params: { venta: true }
        })
            .then(({ data }) => {
                console.log('datadatadata', data)
                this.setState({ quotation: data, })
            })
            .catch(error => {
                message.error(getResponseError(error.response, "Error getting the quotation"))
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    updateStatus = (status, costo = 0) => {
        const { id } = this.props.params
        this.setState({ loading: true })
        axios.put(`/cotizacion`, {
            id,
            status,
            costo
        }).then(({ data }) => {
            this.get()
            message.success(`Quotation ${status === 1 ? 'accepted' : 'refused'}!`)
        }).catch(error => {
            message.error(getResponseError(error.response, "Error updating the quotation"))
            this.setState({ loading: false })
        })
    }

    renderFiles = (item, id) => {
        return (
            <List.Item className="quotation-files-item">
                <Row className="width-100" gutter={[6, 8]} align={"middle"}>
                    <Col xs={10} lg={4}>
                        <Image
                            src={`${axios.defaults?.baseURL}/cotizacion/${id}?imagen=${item.imagen.file}&Authorization=${sessionStorage.getItem('token')}`}
                            placeholder
                            className='item-image'
                        />
                    </Col>
                    <Col xs={14} lg={20}>
                        <Space size={4} direction='vertical'>
                            <Text strong>Description</Text>
                            <Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.descripcion}</Paragraph>
                        </Space>
                    </Col>
                </Row>
            </List.Item>
        )
    }

    setTrack = () => {
        this.setState({ modal_track_visible: true })
    }

    render() {

        const { quotation } = this.state
        const { direccion_id } = quotation

        let address = null
        if (direccion_id?.localizacion) address = [direccion_id.nombre, ...direccion_id.localizacion.place_name.split(',')]
        if (direccion_id?.codigo_postal && !address) address = [direccion_id.nombre, direccion_id.codigo_postal]

        console.log('quotation', quotation)

        return (
            <>
                <PageHeader
                    backIcon={true}
                    className="admin-page-header"
                    title="Quotation"
                    subTitle={FolioFormat(quotation.folio)}
                />
                <Content className="customer-content quotation-details content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                            <CardQuotationInformation
                                quotation={this.state.quotation}
                                can_view={true}
                                loading={this.state.loading}
                                onConfirm={() => this.setState({ modalAccept: true })}
                                updateQuotation={this.updateStatus}
                                updateCosto={() => this.setState({ modalChangeCosto: true })}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <CardQuotationAddress
                                {...this.state.quotation}
                                address={address}
                                can_track={true}
                                venta={this.state.quotation.venta}
                                status={this.state.quotation.status}
                                onTrack={this.setTrack}
                                collection_date={this.state.quotation.collection_date}
                                time_slot={this.state.quotation.time_slot}
                                entrega_local={quotation.entrega_local}

                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <CardQuotationProgress
                                status={this.state.quotation.status}
                                can_pay={false}
                                admin={true}
                                venta={this.state.quotation.venta}
                                cliente={this.state.quotation.cliente_id}
                                is_pay={this.state.venta?.square_payment}
                            />
                        </Col>
                        <Col xs={24}>
                            <Tabs
                                items={[
                                    {
                                        label: 'Files',
                                        key: '1',
                                        children: <Card title={'Files Uploaded'}>
                                            <List
                                                loading={this.state.loading}
                                                className="quotation-files-list"
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Files not found" }}
                                                dataSource={this.state.quotation.imagenes}
                                                renderItem={item => this.renderFiles(item, quotation._id)}
                                            />
                                        </Card>
                                    },
                                    {
                                        label: 'Shipment',
                                        key: '2',
                                        children: <EnviosConfiguracion cotizacion_id={quotation._id} venta={quotation.venta} label_id={quotation.venta?.label_ship} refresh={this.get} can_view={true} disabled_configuration={false} />,
                                        disabled: quotation.status != 1 || (quotation.entrega_local || quotation.pick_and_deliver) || (quotation.venta?.square_payment == false),
                                    }

                                ]}
                            >  </Tabs>

                        </Col>
                    </Row>
                </Content >
                <ModalAceptar
                    visible={this.state.modalAccept}
                    onSuccess={({ costo }) => {
                        this.setState({ modalAccept: false })
                        this.updateStatus(1, costo)
                    }}
                    onClose={() => this.setState({ modalAccept: false })}
                />
                <ModalChangeCosto
                    visible={this.state.modalChangeCosto}
                    update={() => {
                        this.setState({ modalChangeCosto: false })
                        this.get()
                    }}
                    cotizacion_id={this.props.params.id}
                    onClose={() => this.setState({ modalChangeCosto: false })}
                />
                <ModalSetTrackingNumber
                    visible={this.state.modal_track_visible}
                    update={() => {
                        this.setState({ modal_track_visible: false })
                        this.get()
                    }}
                    cotizacion_id={quotation._id}
                    onClose={() => this.setState({ modal_track_visible: false })}
                />
            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const params = useParams();

    return <Quotation {...props} setShowSearch={setShow} navigate={navigate} params={params} />
}